import StarChart from "../StarChart";
import Grid from "@mui/material/Grid";
import HeroReusableV1 from "./HeroReusableV1";
import useScreenSize from "components/customHooks/useScreenSize";

const HeroV2 = () => {
  const versionNumber = "000";
  const screenLayoutType = useScreenSize();

  const getHeight = () => {
    return screenLayoutType === "smallScreen" ? "auto" : 525;
  };

  const getMarginLeftComponent = () => {
    return screenLayoutType === "smallScreen" ? "0 0 5rem" : "auto";
  };
  const getMarginRightComponent = () => {
    return screenLayoutType === "smallScreen" ? "-1rem 0 5rem" : "auto";
  };

  return (
    <div className="layout-container">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        height={getHeight()}
      >
        {/* Short text + buttons */}
        <Grid item lg={5} md={12} sm={12} margin={getMarginLeftComponent()}>
          <HeroReusableV1
            headerWithDesc={false}
            versionNumber={versionNumber}
          />
        </Grid>
        {/* Stars */}
        <Grid item lg={7} md={12} sm={12} margin={getMarginRightComponent()}>
          <Grid container alignItems="center" justifyContent="center">
            <StarChart margin={"6rem 0 51px"} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default HeroV2;
